import { sygnet } from './sygnet'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilCloudDownload,
  cilGraph,
  cilHome,
  cilSpreadsheet, 
  cilAccountLogout, 
  cilPeople, 
  cilBook,
  cilLayers,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilTrash,
  cilSearch,
  cilAudioDescription,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
}, {
  cilCloudDownload,
  cilGraph,
  cilHome,
  cilSpreadsheet, 
  cilAccountLogout, 
  cilPeople, 
  cilBook,
  cilLayers,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilTrash,
  cilSearch,
  cilAudioDescription
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
})
