/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react';
import { cilAccountLogout } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import Utils from '../views/redux/utils';
import UserActions from '../views/redux/middleware/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginPending, loginSuccess, loginFailed } from '../views/redux/reducers/authSlice';

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const history = useHistory();
  const pubemail = Utils.getCurrentUserEmail();
  const pubstatus = Utils.getPubStatus();

  useEffect(() => {
    if (isLoggingOut) {
      const performLogout = async () => {
        try {
          dispatch(loginPending());
          const response = await UserActions.UserLogout();
          if (response) {
            localStorage.clear();
              history.replace('/');
          }
        } catch (error) {
          console.error("Error logging user out:", error);
          dispatch(loginFailed({ message: error.message }))
        } finally {
          dispatch(loginSuccess());
        }
      };
      performLogout();
    }
  }, [isLoggingOut, history]);

  const logout = (e) => {
    e.preventDefault();
    setIsLoggingOut(true);
  };

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={'avatars/8.jpg'}
            className="c-avatar-img"
            alt="UserImage"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          {/* <strong>Home</strong> */}
          <div className="header_email">
          <p><b>{pubemail}</b></p>
        </div>
        </CDropdownItem>
         {/* <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem
          headerh
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem> */}
         <CDropdownItem to="/welcome">
          <CIcon name="cil-home" className="mfe-2" />
          Home
        </CDropdownItem>
        {pubstatus == 1 ? (
          <CDropdownItem to="/dashboard">
            <CIcon name="cil-speedometer" className="mfe-2" />
            Dashboard
          </CDropdownItem>
        ) : null}
        <CDropdownItem divider />
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        {/* <CDropdownItem to="/userprofile">
          <CIcon name="cil-user" className="mfe-2" />
          Profile
        </CDropdownItem> */}
        <CDropdownItem onClick={logout}>
          <CIcon content={cilAccountLogout} className="mfe-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
