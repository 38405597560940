/* eslint-disable */
/* eslint-disable no-unused-vars */
import React from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import error404 from '../../../assets/img/img/error404.jpg';

const NotFoundPage = () => {
  return (
    <CContainer fluid className="min-vh-100 d-flex flex-column justify-content-center align-items-center bg-white">
      <CRow className="w-100 justify-content-center">
        <CCol xs="12" md="10" lg="8">
              <h1 className="mt-2 error_text color_FF6900 d-flex align-items-center content_center">
                Error 404 - Page Not Found
              </h1>
              <div className="d-flex justify-content-center">
                <img src={error404} alt="Coming Soon" className="img-fluid" />
              </div>
              <h6 className="font-weight-bold text-dark l textAlign_center">
                Sorry, the page you are looking for does not exist. Check the URL for any typos or mistakes
              </h6>
              <a href="/welcome" className="btn btn-primary mt-3">
                Go Back to Home
              </a>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default NotFoundPage;
