/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from "../../../assets/logo/Advergic-Logo.svg";
import {
  CSidebarBrand,
  CButton,
  CCardBody,
  CCardGroup,
  CContainer,
  CInputGroup,
  CForm,
  CInput,
  CCard,
  CCol,
  CRow,
  CModalFooter
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import Utils from '../../redux/utils';
import Cookies from 'universal-cookie';
import Notification from "../../components/notifications/notification";
import UserRoleActions from '../../redux/middleware/userroles';
import UserActions from '../../redux/middleware/auth';
import PublisherActions from '../../redux/middleware/publishers';  
import Loader from '../../components/Loader/Loader';
import TheFooter from '../../../containers/TheFooter'
const cookies = new Cookies()

const Login = () => {
  const userRole = Utils.getCurrentUserRole();
  // const userNetworkCode = Utils.getCurrentUserNetworkCode();
  // console.log(userNetworkCode)
  const [error, setError] = useState();
  const [emailInput, setEmailInput] = useState('');
  const [emailerror, setEmailError] = useState();
  const [isEmailSet, setIsEmailSet] = useState(false);
  const [isUserRoleFetched, setIsUserRoleFetched] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const pubemail = Utils.getCurrentUserEmail();
  const authStatus = Utils.getAuthStatus();
  let history = useHistory();

  useEffect(() => {
    if (pubemail && userRole && authStatus && (authStatus!==3)) {
      setIsEmailSet(true);
      history.push('/welcome');
    }
    if (cookies.get('activation_message')) {
      setNotify({
        isOpen: true,
        message: 'Your account is being Activated',
        type: 'success'
      });
    }
  }, [pubemail])

  useEffect(() => {
    if (isEmailSet && isUserRoleFetched) {
      const userRole = Utils.getCurrentUserRole(); // Assuming this retrieves the current role object
      if (userRole && userRole[0]?.roleid === 3) {
        // If user role is 3, redirect to /dashboard
        history.push('/welcome');
      } else {
        // Otherwise, redirect to /welcome
        history.push('/dashboard');
      }
    }
  }, [isEmailSet, isUserRoleFetched, history]);
  

  const submit = async (e) => {
    try{
    setLoading(true);
    e.preventDefault();
    let email = e.target[0].value;
    let password = e.target[1].value;
    setEmailInput(email);
    await postCustomer(email, password);
    }catch(err){
    }finally{
      setLoading(false);
    }
  };

  const postCustomer = async (email, password) => {
    let data = {
      email: email,
      password: password,
    };
    try {
      const response = await UserActions.UserLogin(data);
      if (response?.authStatus === 1) {
        setEmailError(response.msg);
        setError("");
      } else if (response?.authStatus === 2) {
        Utils.setCurrentToken(response?.token);
        Utils.setCurrentUserID(response?.id);
        Utils.setCurrentUserEmail(data?.email);
        Utils.getPubStatus();
        setIsEmailSet(true);
        await fetchCurrentUserRole();
        await fetchUserPropertyId(response?.id);  
        await fetchUserNetworkCode(response?.id);  
      } else if (response?.authStatus === 3) {
        setEmailError(response?.msg);
        setError("");
      } else if (response?.authStatus === 4) {
        setError("Either email or password is not correct!");
        setEmailError("");
      }
    } catch (err) {
    }
  };

  const fetchCurrentUserRole = async () => {
    const pubemail = Utils.getCurrentUserEmail();
    try {
      if (!pubemail) {
        return;
      }
      const response = await UserRoleActions.getCurrentUserRole(pubemail || emailInput);
      if (response && response?.length > 0) {
        Utils.setCurrentUserRole(response);
        setIsUserRoleFetched(true);
      }
    } catch (error) {
      console.error("Error fetching publisher status:", error);
    }
  };

  const fetchUserPropertyId = async () => {
    try {
      const response = await PublisherActions.getPublishersCodes(emailInput); // Passing user-entered email
      const user = response.find(user => user.email === emailInput);
      if (user) {
        Utils.setPropertyId(user.propertyid);
      }
    } catch (error) {
      console.error('Error fetching property ID:', error);
    }
  };
  
  const fetchUserNetworkCode = async () => {
    try {
      const response = await PublisherActions.getPublishersCodes(emailInput); // Passing user-entered email
      const user = response.find(user => user.email === emailInput);
      if (user) {
        Utils.setCurrentUserNetworkCode(user.networkcode);
      }
    } catch (error) {
      console.error('Error fetching NetworkCode:', error);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };
  
  return (
    <>
    {loading ? (
      <Loader/>
    ):(
      <>
    <div className="c-app c-default-layout flex-row align-items-center background_white">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup className="login-form width_63 margin_auto">
              <CCard className="p-0 background_transparent"  style={{border:"0"}}>
                <CCardBody>
                  <CForm className="signup-form mt-n5" onSubmit={e => {
                    submit(e);
                    e.preventDefault();
                  }}>
                    <CSidebarBrand className="justify-content-start" to="/">
                      <Logo className="c-sidebar-brand-full"
                        name="logo-negative"
                        height={42}/>
                      <CIcon
                        className="c-sidebar-brand-minimized"
                        name="sygnet"
                        height={35}
                      />
                    </CSidebarBrand>
                    <h2 className="login-title mt-5 mb-1 text-start"><span className="signup-forms-title">Sign In</span></h2>
                    <p className="login-title text-muted text-start">Enter your credentials to sign in to your account!</p>                    
                      <CInputGroup className="mb-1">
                      <CInput className="input-tag" type="email" name="email" placeholder="Email" autoComplete="email" required value={emailInput} onChange={handleEmailChange} />
                    </CInputGroup>
                    {emailerror ? <h6 className="mb-3 emailError">{emailerror}</h6> : ""}
                    <CInputGroup className="mt-3">
                      <CInput
                        className="input-tag"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                      />
                        <div className="password_button" onClick={togglePasswordVisibility}>
                          {showPassword ? 'Hide' : 'Show' }
                        </div>
                    </CInputGroup>
                    {error ? <h6 className="mt-1 emailError">{error}</h6> : ""}
                    <CRow>
                      <CButton className="button-tag mt-3 px-4 sign_buttons reg-button" type="submit" role="button" ype="submit" >Sign In</CButton>
                    </CRow>
                    <CRow>
                      <CCol className="mb-0">
                        <p className="text-muted mt-3" ><a className="fg-link" href="/forgetPassword">Forgot Password?</a></p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
                <CModalFooter className="border-top-0">
                  <h6 className="text-muted mt-n2"><span className='sign-in-link'>Not a member?</span></h6>
                  <CButton className="mt-n3 button-tag reg-button" component='a' to='/register' role="button" ype="submit" color="secondary" >
                    Register Now
                  </CButton>
                </CModalFooter>
               </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <TheFooter customClass="border-0 background_black mt-4"/>
      </CContainer>
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </div>

      </>
  )}
  </>
  )
}
export default Login
