/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logos from "../assets/logo/Advergic-Logo.svg";
import { useSelector, useDispatch } from 'react-redux';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CSidebarMinimizer,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Utils from '../views/redux/utils';
import routes from '../routes';
import {
  TheHeaderDropdown
} from './index';
import DomainsDropdown from '../views/components/domainsDropdown/domainsDropdown'
import DomainActions from '../views/redux/middleware/domains';

const TheHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userid = Utils.getCurrentUserID();
  const userrole = Utils.getCurrentUserRole();
  const pubemail = Utils.getCurrentUserEmail();
  const sidebarShow = useSelector(state => state.UI.sidebarShow);
  const [domains, setDomains] = useState([]);
  const location = useLocation();

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
    dispatch({ type: 'set', sidebarShow: val });
    
    const sidebarElement = document.querySelector('.c-sidebar'); 
    
    if (val === true) {
      sidebarElement.style.display = 'inherit';
    } else {
      sidebarElement.style.display = 'none';
    }
  
    const links = document.querySelectorAll('c-sidebar-nav-link c-active'); 
    links.forEach(link => {
      link.addEventListener('click', () => {
        if (val === true) {
          sidebarElement.style.display = 'none';
        } else {
          sidebarElement.style.display = 'none';
        }
      });
    });
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/dashboard">
        <CIcon name="logo" src={logos} height="25" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to={location.pathname}> <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          /></CHeaderNavLink>
        </CHeaderNavItem>
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard"> <CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          routes={routes} 
        /></CHeaderNavLink>
        </CHeaderNavItem> */}
        {/* <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem> */}
        {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink>Settings</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <CHeaderNav className="d-md-down-block mr-auto">
          {userrole && userrole?.length > 0 && userrole[0]?.roleid !== 3 ? (
            <CHeaderNavItem className="px-3" >
              <DomainsDropdown />
            </CHeaderNavItem>
          ) : null}
        </CHeaderNav>
        {/* <TheHeaderDropdownNotif/> 
         <TheHeaderDropdownTasks/> 
         <TheHeaderDropdownMssg/> */}
        {pubemail && (<TheHeaderDropdown />)}
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          routes={routes} 
        />
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink 
              className="c-subheader-nav-link" 
              aria-current="page" 
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div>
      </CSubheader> */}
    </CHeader>
  )
}

export default TheHeader
