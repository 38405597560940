/* eslint-disable no-unused-vars */
/* react-hooks/exhaustive-deps */
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
} from '@coreui/react';
import Utils from '../../redux/utils';
import DomainActions from '../../redux/middleware/domains';

const DomainsDropdown = () => {
    const userid = Utils.getCurrentUserID();
    const userNetworkCode = Utils.getCurrentUserNetworkCode();
    const pubemail = Utils.getCurrentUserEmail();
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [selectedDomainURL, setSelectedDomainURL] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Mobile view if width <= 768px
    const prevSearchTerm = useRef('');

    useEffect(() => {
        const advergicEmailRegex = /@advergic\b/i;
        if (advergicEmailRegex.test(pubemail)) {
            fetchDomains();
        } else {
            fetchPubDomains();
        }

        const storedDomain = localStorage.getItem('selectedDomain');
        if (storedDomain) {
            const domain = JSON.parse(storedDomain);
            setSelectedDomain(domain);
            setSelectedDomainURL(domain.domainurl);
        }

        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [pubemail]);

    useEffect(() => {
        prevSearchTerm.current = searchTerm;
    }, [searchTerm]);

    useEffect(() => {
        if (selectedDomain) {
            Utils.setCurrency(selectedDomain.currencyCode);
        }
    }, [selectedDomain]);

    const sortDomains = (domains) => {
        return domains.sort((a, b) => {
            const aName = a.domainname;
            const bName = b.domainname;

            const aIsNumber = !isNaN(aName);
            const bIsNumber = !isNaN(bName);

            if (aIsNumber && bIsNumber) {
                return Number(aName) - Number(bName);
            } else if (aIsNumber) {
                return -1;
            } else if (bIsNumber) {
                return 1;
            } else {
                return aName.localeCompare(bName);
            }
        });
    };

    const fetchDomains = async () => {
        try {
            const response = await DomainActions.getDomains();
            if (response && response?.length > 0) {
                const sortedDomains = sortDomains(response);
                setDomains(sortedDomains);
                if (!localStorage.getItem('selectedDomain')) {
                    setSelectedDomain(sortedDomains[0]);
                    setSelectedDomainURL(sortedDomains[0]?.domainurl);
                    Utils.setPublisherDomain(sortedDomains[0]?.domainurl);
                    Utils.setCurrency(sortedDomains[0]?.currencyCode);
                }
            }
        } catch (error) {
            console.error("Error fetching publisher status:", error);
        }
    };

    const fetchPubDomains = async () => {
        try {
            const response = await DomainActions.getPubDomains(userNetworkCode, pubemail);
    
            if (response && response.length > 0) {
                const filteredDomains = response.filter(domain => domain.email === pubemail);
    
                if (filteredDomains.length > 0) {
                    const sortedDomains = sortDomains(filteredDomains);
                    setDomains(sortedDomains);
    
                    if (!localStorage.getItem('selectedDomain') && sortedDomains.length > 0) {
                        setSelectedDomain(sortedDomains[0]);
                        setSelectedDomainURL(sortedDomains[0]?.domainurl);
                        Utils.setPublisherDomain(sortedDomains[0]?.domainurl);
                        Utils.setCurrency(sortedDomains[0]?.currencyCode);
                    }
                } else {
                    setDomains([]);
                    setSelectedDomain('');
                    setSelectedDomainURL('');
                    Utils.setPublisherDomain('');
                    Utils.setCurrency('');
                }
            }
        } catch (error) {
            console.error("Error fetching publisher status:", error);
        }
    };
    
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '..';
    };

    const handleSelect = (item) => {
        setSelectedDomain(item);
        Utils.setPublisherDomain(item?.domainurl);
        localStorage.setItem('selectedDomain', JSON.stringify(item));
        setSearchTerm('');
        window.location.reload();
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredDomains = domains.filter((item) => {
        return item.domainurl.toLowerCase().includes(searchTerm.toLowerCase());
    });
    
    const filteredUniqueDomains = filteredDomains.filter((item, index, self) => (
        index === self.findIndex((t) => (
            t.domainurl === item.domainurl
        ))
    ));    

    return (
        <CDropdown className="d-block dropdown_input_tag w-100">
            <CDropdownToggle color="primary" className="dropdown-toggle-custom">
                {selectedDomain 
                    ? (isMobileView ? truncateText(selectedDomain.domainname, 13) : selectedDomain.domainname)
                    : 'Select a domain'}
            </CDropdownToggle>
            <CDropdownMenu className="dropdown-menu-custom">
                <div className='dropdown-input-wrapper'>
                    <input
                        className='dropdown-input'
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="custom-scrollbar">
                    {filteredUniqueDomains.length > 0 ? (
                        filteredUniqueDomains.map((item) => (
                            <CDropdownItem
                                value={selectedDomain}
                                key={item.domainurl}
                                onClick={() => handleSelect(item)}
                            >
                                {item.domainurl}
                            </CDropdownItem>
                        ))
                    ) : (
                        <CDropdownItem disabled className="no-items-found">
                            No items found
                        </CDropdownItem>
                    )}
                </div>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default DomainsDropdown;
