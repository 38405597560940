/* eslint-disable */
import React from 'react';
import { Redirect } from 'react-router-dom';
import Utils from '../src/views/redux/utils';

const pubstatus = Utils.getPubStatus();
const userrole = Utils.getCurrentUserRole();
const pubemail = Utils.getCurrentUserEmail();

//Home and Breadcrumbs Routes
const WelcomePage = React.lazy(() => import('./views/pages/welcomePage/welcomePage'));
const ComingSoonPage = React.lazy(() => import('./views/pages/comingsoonpage/ComingSoonPage'));
const Dashboard = React.lazy(() => import('./views/pages/dashboard/dashboard'));
const Analytics = React.lazy(() => import('./views/pages/pubAnalytics/pubAnalytics'));
const Reporting = React.lazy(() => import('./views/pages/pubReporting/pubReporting'));
const UsersList = React.lazy(() => import('./views/pages/usersList/usersList'));
const RegPubsData = React.lazy(() => import('./views/pages/RegPubsData/regsPubsData'));
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));

//Dashboard Routes
const UserProfile = React.lazy(() => import('./views/pages/userProfile/UserProfile'));
import SetupPage from './views/pages/Help/SetupPage';
import NotFoundPage from './views/pages/NotFoundPage/NotFoundPage';

const routes = [
  { path: '/', exact: true },
  { path: '/welcome', name: 'Welcome Page', component: WelcomePage },
  { path: '/comingsoon', name: 'ComingSoon', component: ComingSoonPage },
  { path: '/pagenotfound', name: 'PageNotFound', component: NotFoundPage },
  { path: '/dashboard', name: 'Dashboard', component: pubstatus === 0 && userrole && userrole?.length > 0 && userrole[0]?.roleid === 7 ? () => <Redirect to="/welcome" /> : Dashboard },
  { path: '/analytics', name: 'Analytics', component: Analytics },
  { path: '/integrations', name: 'Integrations', component: SetupPage },
  { path: '/reporting', name: 'Reporting', component: Reporting },
  { path: '/userslist', name: 'UsersList', component: UsersList },
  { path: '/pubsdata', name: 'PubsData', component: RegPubsData },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  //Dashboard Routes
  { path: '/userprofile', name: 'UserProfile', component: UserProfile },
];

export default routes;
