/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import axios from 'axios';
 

async function CallApi(apiOptions) {
  let apiResponse = {};
  const config = {
    method: apiOptions?.method,
    url: apiOptions?.endpoint,
    headers: apiOptions?.headers,
    data: apiOptions?.data,
    params: apiOptions?.params,
    responseType: apiOptions?.responseType,
  };

  await axios(config)
    .then((result) => {
      apiResponse = result;
    })
    .catch((error) => {       
      apiResponse = error;
    });

  return apiResponse;
}

const setCurrentUserID = (id) => {
  try {
    if (id) {
      localStorage.setItem('__avUserID', JSON.stringify(id));
    } else {
      localStorage.removeItem('__avUserID');
    }
  } catch (error) {
  }
};

const getCurrentUserID = () => {
  let id = null;
  try {
    id =
      localStorage.getItem('__avUserID') != null
        ? JSON.parse(localStorage.getItem('__avUserID'))
        : null;
  } catch (error) {
    id = null;
  }
  return id;
};

const setCurrentUserNetworkCode = (networkcode) => {
  try {
    if (networkcode) {
      // Store the network code as a string without unnecessary JSON.stringify
      localStorage.setItem('__avUserNetworkCode', networkcode);
    } else {
      localStorage.removeItem('__avUserNetworkCode');
    }
  } catch (error) {
    console.error('Error setting network code:', error);
  }
};

const getCurrentUserNetworkCode = () => {
  let networkcode = null;
  try {
    const storedCode = localStorage.getItem('__avUserNetworkCode');
    networkcode = storedCode !== null ? storedCode : null;
  } catch (error) {
    console.error('Error getting network code:', error);
    networkcode = null;
  }
  return networkcode;
};


const setCurrentUserEmail = (email) => {
  try {
    if (email) {
      localStorage.setItem('__avUserMail', JSON.stringify(email));
    } else {
      localStorage.removeItem('__avUserMail');
    }
  } catch (error) {
  }
};

const getCurrentUserEmail = () => {
  let email = null;
  try {
    email =
      localStorage.getItem('__avUserMail') != null
        ? JSON.parse(localStorage.getItem('__avUserMail'))
        : null;
  } catch (error) {
    email = null;
  }
  return email;
};

const setCurrentToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('__avPubToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('__avPubToken');
    }
  } catch (error) {
  }
};

const getCurrentToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('__avPubToken') != null
        ? JSON.parse(localStorage.getItem('__avPubToken'))
        : null;
  } catch (error) {
    token = null;
  }
  return token;
};

const setPublisherDomain = (domain) => {
  try {
    if (domain) {
      localStorage.setItem('__avPubDomain', JSON.stringify(domain));
    } else {
      localStorage.removeItem('__avPubDomain');
    }
  } catch (error) {
  }
};

const getPublisherDomain = () => {
  let domain = null;
  try {
    domain =
      localStorage.getItem('__avPubDomain') != null
        ? JSON.parse(localStorage.getItem('__avPubDomain'))
        : null;
  } catch (error) {
    domain = null;
  }
  return domain;
};

const setCurrency = (currency) => {
  try {
    if (currency) {
      localStorage.setItem('__avGAMCurrency', JSON.stringify(currency));
    } else {
      localStorage.removeItem('__avGAMCurrency');
    }
  } catch (error) {
  }
};

const getCurrency = () => {
  let currency = null;
  try {
    currency =
      localStorage.getItem('__avGAMCurrency') != null
        ? JSON.parse(localStorage.getItem('__avGAMCurrency'))
        : null;
  } catch (error) {
    currency = null;
  }
  return currency;
};

const setCurrentUserRole = (role) => {
  try {
    if (role) {
      localStorage.setItem('__avCurrentUserRole', JSON.stringify(role));
    } else {
      localStorage.removeItem('__avCurrentUserRole');
    }
  } catch (error) {
  }
};

const getCurrentUserRole = () => {
  let role = null;
  try {
    role =
      localStorage.getItem('__avCurrentUserRole') != null
        ? JSON.parse(localStorage.getItem('__avCurrentUserRole'))
        : null;
  } catch (error) {
    role = null;
  }
  return role;
};

const setUserRoles = (role) => {
  try {
    if (role) {
      localStorage.setItem('__avUserRoles', JSON.stringify(role));
    } else {
      localStorage.removeItem('__avUserRoles');
    }
  } catch (error) {
  }
};

const getUserRoles = () => {
  let role = null;
  try {
    role =
      localStorage.getItem('__avUserRoles') != null
        ? JSON.parse(localStorage.getItem('__avUserRoles'))
        : null;
  } catch (error) {
    role = null;
  }
  return role;
};

const setPubStatus = (status) => {
  try {
    if (status) {
      localStorage.setItem('__avPubStatus', status);
    } else {
      localStorage.removeItem('__avPubStatus');
    }
  } catch (error) {
  }
};

const getPubStatus = () => {
  let status = null;
  try {
    status =
      localStorage.getItem('__avPubStatus') !== ''
        ? JSON.parse(localStorage.getItem('__avPubStatus'))
        : '';
  } catch (error) {
    status = '';
  }
  return status;
};

const setPropertyId = (propertyid) => {
  try {
    if (propertyid) {
      localStorage.setItem('__avPropertyId', propertyid);
    } else {
      localStorage.removeItem('__avPropertyId');
    }
  } catch (error) {
  }
};

const getPropertyId = () => {
  let status = null;
  try {
    status =
      localStorage.getItem('__avPropertyId') !== ''
        ? JSON.parse(localStorage.getItem('__avPropertyId'))
        : '';
  } catch (error) {
    status = '';
  }
  return status;
};

const setAuthStatus = (status) => {
  try {
    if (status) {
      localStorage.setItem('__avAuthStatus', status.toString());
    } else {
      localStorage.removeItem('__avAuthStatus');
    }
  } catch (error) {
    console.error('Error setting auth status:', error);
  }
};

const getAuthStatus = () => {
  try {
    const status = localStorage.getItem('__avAuthStatus');
    return status ? parseInt(status) : null;
  } catch (error) {
    console.error('Error getting auth status:', error);
    return null;
  }
};

const Utils = {
  CallApi,
  setCurrentUserID,
  getCurrentUserID,
  setCurrentUserNetworkCode,
  getCurrentUserNetworkCode,
  setCurrentUserEmail,
  getCurrentUserEmail,
  setCurrentToken,
  getCurrentToken,
  setPublisherDomain,
  getPublisherDomain,
  setCurrentUserRole,
  getCurrentUserRole,
  setCurrency,
  getCurrency,
  setUserRoles,
  getUserRoles,
  setPubStatus,
  getPubStatus,
  setPropertyId,
  getPropertyId,
  setAuthStatus,
  getAuthStatus,
};

export default Utils;
