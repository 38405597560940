/* eslint-disable no-unused-vars */
/* react-hooks/exhaustive-deps */
/* eslint-disable */
import React from 'react'

const Loader = ({marginBottom}) => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="loader" style={{marginBottom:marginBottom}}>
            <div className="bar bar-small"></div>
            <div className="bar bar-medium"></div>
            <div className="bar bar-large"></div>
          </div>
        </div>
    </div>
  )
}

export default Loader;