/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import logos from "../assets/logo/Advergic-Logo.svg";
import { useSelector, useDispatch } from 'react-redux';
import { cilSpreadsheet, cilAccountLogout, cilPeople, cilBook } from '@coreui/icons';
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Utils from '../views/redux/utils';
import UserActions from '../views/redux/middleware/auth';
import { loginPending, loginSuccess, loginFailed } from '../views/redux/reducers/authSlice';

const TheSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pubstatus = Utils.getPubStatus();
  const userrole = Utils.getCurrentUserRole();
  const show = useSelector(state => state.UI.sidebarShow);
  const pubemail = Utils.getCurrentUserEmail();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 995);
  const [forceRender, setForceRender] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(Utils.getCurrentUserRole());

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 995);
  };

  // Listen for storage changes
  const handleStorageChange = useCallback((e) => {
    if (e.key === '__avCurrentUserRole') {
      setCurrentUserRole(Utils.getCurrentUserRole());
    } else if (e.key === '__avPubStatus') {
      setPubStatus(Utils.getPubStatus());
    } else if (e.key === '__avUserMail') {
      setPubEmail(Utils.getCurrentUserEmail());
    }
  }, []);

  // Set up event listeners
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    
    // Poll for local storage changes every second
    const interval = setInterval(() => {
      const newUserRole = Utils.getCurrentUserRole();
      const newPubStatus = Utils.getPubStatus();
      const newPubEmail = Utils.getCurrentUserEmail();
      
      if (JSON.stringify(newUserRole) !== JSON.stringify(currentUserRole)) {
        setCurrentUserRole(newUserRole);
      }
    }, 100);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, [handleStorageChange, currentUserRole]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLoggingOut) {
      const performLogout = async () => {
        try {
          dispatch(loginPending());
          const response = await UserActions.UserLogout();
          if (response) {
            localStorage.clear();
              history.replace('/');
          }
        } catch (error) {
          console.error("Error logging user out:", error);
          dispatch(loginFailed({ message: error.message }))
        } finally {
          dispatch(loginSuccess());
        }
      };
      performLogout();
    }
  }, [isLoggingOut, history]);

  const handleToggleClick = () => {
    dispatch({ type: 'set', sidebarShow: !show });
  };

  const handleLinkClick = useCallback(() => {
    if (isMobile) {
      dispatch({ type: 'set', sidebarShow: false });
      setForceRender(!forceRender);
    }
  }, [isMobile, dispatch, forceRender]);

  const logout = (e) => {
    e.preventDefault();
    setIsLoggingOut(true);
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <>
      <CSidebar
        key={`${forceRender}-${JSON.stringify(currentUserRole)}`}
        show={show}
        onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })}
      >
        <CSidebarBrand className="sidebar-logo d-md-down-none sidebarlogo_" to={pubstatus === 1 ? "/dashboard" : "/welcome"}>
          <CIcon className="c-sidebar-brand-full mt-4" name="logo-negative" src={logos} height={35} />
          <img className="c-sidebar-brand-minimized" src="https://advergic.com/wp-content/uploads/2024/02/av-site-favicon-1-150x150.png" alt="Brand Logo" height={35} />
        </CSidebarBrand>

        <CSidebarNav>
          {pubemail && (
            <>
              <CSidebarNavTitle>Home</CSidebarNavTitle>
              <Link to='/welcome' className={`c-sidebar-nav-item nav-Link ${isActive('/welcome')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/welcome')}`}>
                  <span className='sidebarlink_'>
                    <CIcon name="cil-home" customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Home</span>
                  </span>
                </div>
              </Link>
            </>
          )}

          {userrole && userrole.length > 0 && userrole[0]?.roleid !== 3 ? (
            <>
              <CSidebarNavTitle>Dashboard</CSidebarNavTitle>
              <Link to='/dashboard' className={`c-sidebar-nav-item nav-Link ${isActive('/dashboard')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/dashboard')}`}>
                  <span className='sidebarlink_'>
                    <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Dashboard</span>
                  </span>
                </div>
              </Link>
              <Link to="/reporting" className={`c-sidebar-nav-item nav-Link ${isActive('/reporting')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/reporting')}`}>
                  <span className='sidebarlink_'>
                    <CIcon content={cilSpreadsheet} customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Reporting</span>
                  </span>
                </div>
              </Link>
              <Link to="/analytics" className={`c-sidebar-nav-item nav-Link ${isActive('/analytics')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/analytics')}`}>
                  <span className='sidebarlink_'>
                    <CIcon name="cil-graph" customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Analytics</span>
                  </span>
                </div>
              </Link>
            </>
          ) : null}

          {userrole && userrole.length > 0 && (userrole[0]?.roleid === 1 || userrole[0]?.roleid === 2 || userrole[0]?.roleid === 4 || userrole[0]?.roleid === 5) ? (
            <>
              <CSidebarNavTitle>Administration</CSidebarNavTitle>
              <Link to="/userslist" className={`c-sidebar-nav-item nav-Link ${isActive('/userslist')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/userslist')}`}>
                  <span className='sidebarlink_'>
                    <CIcon content={cilPeople} customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Users</span>
                  </span>
                </div>
              </Link>
              <Link to="/pubsdata" className={`c-sidebar-nav-item nav-Link ${isActive('/pubsdata')}`} onClick={handleLinkClick}>
                <div className={`cursor c-sidebar-nav-link ${isActive('/pubsdata')}`}>
                  <span className='sidebarlink_'>
                    <CIcon content={cilBook} customClasses="c-sidebar-nav-icon icons_class" />
                    <span className="sidebar-link">Publishers</span>
                  </span>
                </div>
              </Link>
            </>
          ) : null}

          <CSidebarNavTitle>Help</CSidebarNavTitle>
          <Link to="/integrations" className={`c-sidebar-nav-item nav-Link ${isActive('/integrations')}`} onClick={handleLinkClick}>
            <div className={`cursor c-sidebar-nav-link ${isActive('/integrations')}`}>
              <span className='sidebarlink_'>
                <CIcon name="cil-layers" customClasses="c-sidebar-nav-icon icons_class" />
                <span className="sidebar-link">Integrations</span>
              </span>
            </div>
          </Link>

          {pubemail && (
          <>
            <CSidebarNavTitle>Account</CSidebarNavTitle>
            <div className="c-sidebar-nav-item nav-Link account">
              <div 
                className="cursor c-sidebar-nav-link c-active"
                onClick={logout}
                role="button"
                style={{ cursor: 'pointer' }}
              >
                <span className='sidebarlink_'>
                  <CIcon content={cilAccountLogout} customClasses="c-sidebar-nav-icon icons_class" />
                  <div>Logout</div>
                </span>
              </div>
            </div>
          </>
        )}
        </CSidebarNav>
        <CSidebarMinimizer className="c-d-md-down-none" onClick={handleToggleClick} />
      </CSidebar>
    </>
  );
}

export default React.memo(TheSidebar);
