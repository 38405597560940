import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Utils from '../src/views/redux/utils';

const Protected = (props) => {
  let Cmp = props.render;
  let history = useHistory();
  const pubemail = Utils.getCurrentUserEmail();

  useEffect(() => {
    if (!pubemail) {
      history.push('/');
    }
  }, [history, pubemail])

  return (
    <div>
      <Cmp />
    </div>
  )
}

export default Protected
