import Config from "../../../config";
import Utils from '../../redux/utils';

const baseUri = Config.API_BASE_URI;

const getAllPubsData = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/getpubdetails`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPubData = async (email) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/show/` + email,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const addPubData = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/addpubdetails`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const addNewPubData = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/addnewpubdetails`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const savePubDataProgress = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/savepubdetprogress`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const deletePubDetails = async (id, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/deletepubdetails/` + id,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'DELETE',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPubStatus = async (email) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpubdetails/getpubstatus/` + email,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPublishers = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/getusers`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPublishersCodes = async (email) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/getuserscodes/${email}`, // Email added to endpoint
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const Publishers = {
  getAllPubsData,
  getPublishers,
  getPublishersCodes,
  getPubData,
  addPubData,
  savePubDataProgress,
  getPubStatus,
  addNewPubData,
  deletePubDetails
};

export default Publishers;
